import React from 'react'
import "normalize.css"
import projectStyles from './sass/projects.module.scss'

const Projects = () => {
    return (
        <div className={projectStyles.sectionProjects}>
            <div className={projectStyles.centerText}>
                <h2 id="projects" className={[projectStyles.headingSecondary, projectStyles.marginBottom].join(' ')}>
                    Featured projects
                </h2>
                <div className={projectStyles.row}>
                    <div className={projectStyles.col3}>
                        <div className={projectStyles.card}>
                            <div className={[projectStyles.cardSide, projectStyles.cardSideFront].join(' ')}>
                                <div className={[projectStyles.cardPicture, projectStyles.cardPicture1].join(' ')}>
                                    &nbsp;
                                </div>
                                <h4 className={projectStyles.cardHeading}>
                                    <span className={[projectStyles.cardHeadingSpan, projectStyles.cardHeadingSpan1].join(' ')}> Expense app</span>  
                                </h4>
                                <div className={projectStyles.cardDetails}>
                                    <ul>
                                        <li>Expense manager tracker</li>
                                        <li>Easy to use</li>
                                        <li>React, Redux, Heroku</li>
                                        <li>Google Auth with firebase</li>
                                        <li>Difficulty: Hard</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={[projectStyles.cardSide, projectStyles.cardSideBack, projectStyles.cardSideBack1].join(' ')}>
                                <div className={projectStyles.cardCta}>
                                    <div className={projectStyles.cardDescriptionBox}>
                                        <p className={projectStyles.cardDescription}>Did you find interesting?</p>
                                    </div>
                                    <a href="https://walter-expensify-app.herokuapp.com/" className={[projectStyles.btn, projectStyles.btnWhite].join(' ')} target="__blank">Go check!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={projectStyles.col3}>
                        <div className={projectStyles.card}>
                            <div className={[projectStyles.cardSide, projectStyles.cardSideFront].join(' ')}>
                                <div className={[projectStyles.cardPicture, projectStyles.cardPicture2].join(' ')}>
                                    &nbsp;
                                </div>
                                <h4 className={projectStyles.cardHeading}>
                                    <span className={[projectStyles.cardHeadingSpan, projectStyles.cardHeadingSpan2].join(' ')}> Taqueria Carnitas Chela</span>
                                </h4>
                                <div className={projectStyles.cardDetails}>
                                    <ul>
                                        <li>Taqueria web site</li>
                                        <li>Business information</li>
                                        <li>Heroku, git, SEO</li>
                                        <li>Node, express, jade</li>
                                        <li>Difficulty: Easy</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={[projectStyles.cardSide, projectStyles.cardSideBack, projectStyles.cardSideBack2].join(' ')}>
                                <div className={projectStyles.cardCta}>
                                    <div className={projectStyles.cardDescriptionBox}>
                                        <p className={projectStyles.cardDescription}>Did you find interesting?</p>
                                    </div>
                                    <a href="http://www.carnitaschela.mx" className={[projectStyles.btn, projectStyles.btnWhite].join(' ')} target="__blank">Go check!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={projectStyles.col3}>
                        <div className={projectStyles.card}>
                            <div className={[projectStyles.cardSide, projectStyles.cardSideFront].join(' ')}>
                                <div className={[projectStyles.cardPicture, projectStyles.cardPicture3].join(' ')}>
                                    &nbsp;
                                </div>
                                <h4 className={projectStyles.cardHeading}>
                                    <span className={[projectStyles.cardHeadingSpan, projectStyles.cardHeadingSpan3].join(' ')}> Indecision app</span>
                                </h4>
                                <div className={projectStyles.cardDetails}>
                                    <ul>
                                        <li>Idea picker</li>
                                        <li>Sort algorithm</li>
                                        <li>React, Sass</li>
                                        <li>Node, webpack, babel</li>
                                        <li>Difficulty: Medium</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={[projectStyles.cardSide, projectStyles.cardSideBack,projectStyles.cardSideBack3].join(' ')}>
                                <div className={projectStyles.cardCta}>
                                    <div className={projectStyles.cardDescriptionBox}>
                                        <p className={projectStyles.cardDescription}>Did you find interesting?</p>
                                    </div>
                                    <a href="https://walter-indecision-app.netlify.app/" className={[projectStyles.btn, projectStyles.btnWhite].join(' ')} target="__blank">Go check!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={[projectStyles.centerText, projectStyles.marginTopBig].join(' ')}>
                 {/*<Link to={"#"} className={[projectStyles.buttonText].join(' ')}>Discover all projects &rarr;</Link>*/}
            </div>
        </div>
    )
}

export default Projects